<template>
  <div class="personal-info">
    <div class="cui__utils__heading mb-0">
      <strong>PPDB</strong>
    </div>
    <hr />
    <!-- <div class="container" v-if="isPPDBuuid"> -->
    <div class="container" v-if="isPPDBuuid">
      <a-alert
        class="mb-5"
        message="Registered at PPDB"
        description="Can't fill the form anymore, you have filled PPDB form."
        type="success"
        show-icon
      />
      <div>
        <div class="font-size-16 font-weight-bold">
          Akta Lahir :
        </div>
        <div class="child-container mt-2">
          <div>
            <a
              v-if="akta_lahir.id"
              target="_blank"
              download
              :href="`${config.apiUrl}/${akta_lahir.filepath}${akta_lahir.filename}`"
            >
              {{ akta_lahir.filename }}
            </a>
            <p v-else>No File</p>
          </div>
          <a-upload
            name="akta_lahir"
            :value="file_akta_lahir"
            :show-upload-list="false"
            :multiple="false"
            :before-upload="(info) => beforeUpload(info, 'akta_lahir')"
            @change="(val) => handleChangeFile(val, 'akta_lahir')"
          >
            <a-button size="large" ><a-icon type="upload" /> Upload </a-button>
          </a-upload>
        </div>
        <hr>
      </div>
      <div>
        <div class="font-size-16 font-weight-bold">
          Ijazah :
        </div>
        <div class="child-container mt-2">
          <div class="mr-5">
            <a
              v-if="ijazah.id"
              target="_blank"
              download
              :href="`${config.apiUrl}/${ijazah.filepath}${ijazah.filename}`"
            >
              {{ ijazah.filename }}
            </a>
            <p v-else>No File</p>
          </div>
          <a-upload
            name="ijazah"
            :value="file_ijazah"
            :show-upload-list="false"
            :multiple="false"
            :before-upload="(info) => beforeUpload(info, 'ijazah')"
            @change="(val) => handleChangeFile(val, 'ijazah')"
          >
            <a-button size="large" ><a-icon type="upload" /> Upload </a-button>
          </a-upload>
        </div>
        <hr>
      </div>
      <div>
        <div class="font-size-16 font-weight-bold">
          Kartu Keluarga :
        </div>
        <div class="child-container mt-2">
          <div class="mr-5">
            <a
              v-if="kartu_keluarga.id"
              target="_blank"
              download
              :href="`${config.apiUrl}/${kartu_keluarga.filepath}${kartu_keluarga.filename}`"
            >
              {{ kartu_keluarga.filename }}
            </a>
            <p v-else>No File</p>
          </div>
          <a-upload
            name="kartu_keluarga"
            :value="file_kartu_keluarga"
            :show-upload-list="false"
            :multiple="false"
            :before-upload="(info) => beforeUpload(info, 'kartu_keluarga')"
            @change="(val) => handleChangeFile(val, 'kartu_keluarga')"
          >
            <a-button size="large" ><a-icon type="upload" /> Upload </a-button>
          </a-upload>
        </div>
        <hr>
      </div>
      <div>
        <div class="font-size-16 font-weight-bold">
          Rapor :
        </div>
        <div class="child-container mt-2">
          <div class="mr-5">
            <a v-if="rapor.id"
              target="_blank"
              download
              :href="`${config.apiUrl}/${rapor.filepath}${rapor.filename}`"
            >
              {{ rapor.filename }}
            </a>
            <p v-else>No File</p>
          </div>
          <a-upload
            name="rapor"
            :value="file_rapor"
            :show-upload-list="false"
            :multiple="false"
            :before-upload="(info) => beforeUpload(info, 'rapor')"
            @change="(val) => handleChangeFile(val, 'rapor')"
          >
            <a-button size="large" ><a-icon type="upload" /> Upload </a-button>
          </a-upload>
        </div>
        <hr>
      </div>
    </div>
    <div v-else>
      <!-- <unregistered @fetch-master-ppdb="fetchPPDB"/> -->
      <ppdb-input v-if="dataPPDB.id" :dataPpdb="dataPPDB" @fetch-ppdb="fetchUserPPDB"></ppdb-input>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import axios from 'axios'
// const Unregistered = () => import('./Unregistered')
const PpdbInput = () => import('./PpdbInput')

export default {
  name: 'ppdb',
  components: {
    // Unregistered,
    PpdbInput,
  },
  props: {
    isFinish: {
      type: Boolean,
    },
  },
  data() {
    return {
      config,
      loadingUpload: false,
      file_akta_lahir: null,
      file_ijazah: null,
      file_kartu_keluarga: null,
      file_rapor: null,
      akta_lahir: null,
      ijazah: null,
      kartu_keluarga: null,
      rapor: null,
      dataPPDB: {},
      isPPDBuuid: false,
    }
  },
  methods: {
    beforeUpload(info, type) {
      this[`file_${type}`] = info
      return false
    },
    async handleChangeFile(value, type) {
      try {
        this.loadingUpload = true
        this[`file_${type}`] = value.file
        const formData = new FormData()
        formData.append('uuid', this.dataPPDB.nomor_registrasi_ppdb)
        formData.append('fieldType', type)
        formData.append('lampiran_ppdb', this[`file_${type}`])
        const { data } = await axios.put(`${config.apiUrl}/api/file/single-ppdb`, formData, {
          headers: {
            token: localStorage.token,
          },
        })
        this.loadingUpload = false
        if (data.message === 'SUCCESS_UPLOAD_LAMPIRAN_PPDB') {
          this.$notification.success({
            message: 'Success',
            description:
              `SUCCESS ADD / UPDATE PPDB FILE: ${type}`,
          })
        } else {
          this.$notification.error({
            message: 'Error',
            description:
              `ERROR ADD / UPDATE PPDB FILE: ${type}`,
          })
        }
        this.$emit('fetch-ppdb')
      } catch (err) {
        console.log(err)
      }
    },
    ppdbIsFinished() {
      this.$emit('ppdbIsFinished')
    },
    async fetchUserPPDB() {
      try {
        const res = await this.$store.dispatch('ppdb/GET_PPDB_BY_STUDENT_TOKEN')
        // console.log('fetchUserPPDB', res)
        this.dataPPDB = res.dataPPDB
        if (res.dataPPDB.nomor_registrasi_ppdb) {
          this.isPPDBuuid = true
          this.$store.commit('ppdb/SET_STATE', {
            ppdbIsFulfilled: true,
          })
        }
        this.akta_lahir = res.fileAktaLahir
        this.ijazah = res.fileIjazah
        this.kartu_keluarga = res.fileKK
        this.rapor = res.fileRapor
      } catch (err) {
        console.log(err)
      }
    },
  },
  created() {
    this.fetchUserPPDB()
  },
}
</script>

<style lang="scss">
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .child-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
