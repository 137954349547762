<template>
  <div>
    <a-row :gutter="16">
      <a-col :sm="24" :lg="7">
        <card>
          <Menu
            :menus="menus"
            :selectedMenu="selectedMenu"
            @menu-change="menuChange"
          />
        </card>
      </a-col>
      <a-col :sm="24" :lg="17">
        <card>
          <div v-if="selectedMenu[0] === 1">
            <personal-information
              :userData="userData"
              :role="role"
              :firstClick="firstClickVerify"
              @click-verify="verifyEmail"
            />
          </div>
          <div v-else-if="selectedMenu[0] === 2">
            <edit-email
              :email="currentEmail"
              :loadingEmailChange="loadingEmailChange"
              @on-change-email="emailChange"
            />
          </div>
          <div v-else-if="selectedMenu[0] === 3">
            <edit-password
              :password="password"
              @on-change-password="passwordChange"
            />
          </div>
          <div v-else-if="selectedMenu[0] === 4">
              <!-- @download-file="downloadFile" -->
            <ppdb
              @ppdbIsFinished="ppdbIsFinished"
              :dataPPDB="dataPPDB"
              :isPPDBuuid="isPPDBuuid"
              :akta_lahir="akta_lahir"
              :ijazah="ijazah"
              :kartu_keluarga="kartu_keluarga"
              :rapor="rapor"
              @fetch-ppdb="fetchUserPPDB"
            />
          </div>
        </card>
      </a-col>
    </a-row>
    <!-- <Account /> -->
  </div>
</template>

<script>
// import Account from '@/components/app/Account'
import Card from '@/components/app/Card'
import Menu from '@/components/app/Menu'
import PersonalInformation from '@/components/app/Account/PersonalInformation'
import EditEmail from '@/components/app/Account/EditEmail'
import EditPassword from '@/components/app/Account/EditPassword'
import PPDB from '@/components/app/Account/PPDB'

const menus = [
  {
    key: 1,
    icon: 'user',
    name: 'Personal Information',
  },
  {
    key: 2,
    icon: 'mail',
    name: 'Edit Email',
  },
  {
    key: 3,
    icon: 'key',
    name: 'Edit Password',
  },
  {
    key: 4,
    icon: 'profile',
    name: 'PPDB',
  },
]
const selectedMenu = [1]
const password = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
}

export default {
  components: {
    // Account,
    Card,
    Menu,
    PersonalInformation,
    EditEmail,
    EditPassword,
    ppdb: PPDB,
  },
  data() {
    return {
      menus,
      selectedMenu,
      password,
      userData: {},
      currentEmail: '',
      loadingEmailChange: false,
      firstClickVerify: true,
      dataPPDB: {},
      isPPDBuuid: false,
      akta_lahir: {},
      ijazah: {},
      kartu_keluarga: {},
      rapor: {},
      isFinish: true,
    }
  },
  methods: {
    menuChange(payload) {
      if (this.selectedMenu[0] === 4 && payload.key !== 4 && !this.ppdbIsFulfilled) {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        if (!answer) {
          this.selectedMenu = [4]
          return
        }
        window.removeEventListener('beforeunload', null)
        window.removeEventListener('unload', null)
      }
      this.$store.commit('ppdb/SET_STATE', {
        ppdbIsFulfilled: payload.key !== 4,
      })
      // this.isFinish = payload.key !== 4
      this.selectedMenu = [payload.key]
    },
    async fetchUserPPDB() {
      try {
        const res = await this.$store.dispatch('ppdb/GET_PPDB_BY_STUDENT_TOKEN')
        // console.log('fetchUserPPDB', res)
        this.dataPPDB = res.dataPPDB
        if (res.dataPPDB.nomor_registrasi_ppdb) {
          this.isPPDBuuid = true
        }
        this.akta_lahir = res.fileAktaLahir
        this.ijazah = res.fileIjazah
        this.kartu_keluarga = res.fileKK
        this.rapor = res.fileRapor
      } catch (err) {
        console.log(err)
      }
    },
    fetchUserById() {
      const payload = {
        id: this.user.id,
        role: this.role,
      }
      const parseAlamatLengkap = (obj = {}) => {
        const { jalan, rt, rw, kecamatan, kelurahan_desa: kelurahan, kota, provinsi, kode_pos: kodePos } = obj
        let str = jalan
        if (rt) str += ` RT 0${Number(rt) > 10 ? rt : `0${rt}`} `
        if (rw) str += `RW 0${Number(rw) > 10 ? rw : `0${rw}`} `
        if (kecamatan) str += `KEC. ${kecamatan} `
        if (kelurahan) str += `KEL. ${kelurahan} `
        if (kota) str += `${kota} `
        if (provinsi) str += `${provinsi} `
        if (kodePos) str += `${kodePos} `

        return str
      }
      this.$store.dispatch('user/GET_USER_BY_ID', { payload })
        .then(res => {
          // console.log(res)
          this.userData = {
            ...res,
            alamat: res.alamat || parseAlamatLengkap(res.alamat_lengkap),
          }
          this.currentEmail = res.email
        })
    },
    verifyEmail() {
      if (this.firstClickVerify) {
        this.firstClickVerify = false
        const payload = {
          id: this.user.id,
          email: this.currentEmail,
          role: this.role,
        }
        this.$store.dispatch('user/SEND_EMAIL', { payload })
          .then(res => {
            if (res === 'EMAIL_SENT') {
              this.$notification.warning({
                message: 'Warning',
                description: 'Link verification has been sent. Please check your email.',
              })
            } else {
              this.$notification.error({
                message: 'Error',
                description: 'Link verification has not been sent.',
              })
            }
            setTimeout(() => {
              this.$notification.success({
                message: 'Warning',
                description: 'Your link verification is enabled for now.',
              })
              this.firstClickVerify = true
            }, 15000)
          })
      } else {
        this.$notification.warning({
          message: 'Warning',
          description: 'Your link verification is disabled for 15 second, Try again later.',
        })
      }
    },
    emailChange(payload) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!re.test(String(payload).toLowerCase())) {
        this.$notification.error({
          message: 'Error.',
          description: 'Your email is not valid.',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure you want to change your email ? this changes will be need verify your email again</div>
          ),
          onOk: () => {
            const dataIncome = {
              id: this.user.id,
              email: payload,
              role: this.role,
            }
            this.loadingEmailChange = true
            this.$store.dispatch('user/UPDATE_EMAIL', { dataIncome })
              .then(res => {
                this.loadingEmailChange = false
                if (res.message === 'EMAIL_SENT') {
                  this.$notification.warning({
                    message: 'Warning',
                    description: 'Your email has been updated. Please check your email to verify your account',
                  })
                  this.fetchUserById()
                  // this.selectedMenu = [1]
                } else if (res.message === 'IDENTICAL_EMAIL') {
                  this.$notification.warning({
                    message: 'Warning',
                    description: 'There is no change detected.',
                  })
                } else {
                  this.$notification.error({
                    message: 'Error',
                    description: 'Your email has not been updated.',
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Yes',
        })
      }
    },
    passwordChange(payload) {
      // console.log(payload)
      const { currentPassword, newPassword, confirmNewPassword } = payload
      const newData = {}
      // console.log(this.newPassword.length)
      if (currentPassword && newPassword && confirmNewPassword) {
        if (newPassword.length >= 6) {
          if (newPassword === confirmNewPassword) {
            if (this.role === 'Teacher') {
              newData.tipe = this.role
              newData.username = this.user.nik
            } else if (this.role === 'Student') {
              newData.tipe = this.role
              newData.username = this.user.nis
            } else if (this.role === 'Admin') {
              newData.tipe = this.role
              newData.username = this.user.username
            }
            newData.currentPassword = currentPassword
            newData.newPassword = newPassword
            this.$store.dispatch('user/CHANGE_PASSWORD', {
              newData,
            })
              .then(res => {
                if (res === 'SUCCESS_RESET_PASSWORD') {
                  this.$notification.success({
                    message: 'Success.',
                    description: 'Succes Change Password',
                  })
                  this.password.currentPassword = ''
                  this.password.newPassword = ''
                  this.password.confirmNewPassword = ''
                } else if (res === 'INVALID_PASSWORD') {
                  this.$notification.error({
                    message: 'Sorry.',
                    description: 'Current password do not correct',
                  })
                  this.password.currentPassword = ''
                  this.password.newPassword = ''
                  this.password.confirmNewPassword = ''
                }
              })
          } else {
            this.$notification.error({
              message: 'Sorry.',
              description: 'New password do not match',
            })
          }
        } else {
          this.$notification.error({
            message: 'Error',
            description:
              'New password too short, minimum 6 characters',
          })
        }
      } else {
        this.$notification.error({
          message: 'Sorry.',
          description: 'You must fill all forms',
        })
      }
    },
    ppdbIsFinished({ noRefetch = false }) {
      // this.isFinish = true
      this.$store.commit('ppdb/SET_STATE', {
        ppdbIsFulfilled: true,
      })
      if (!noRefetch) this.fetchUserPPDB()
    },
  },
  beforeRouteLeave (to, from, next) {
    if (!this.ppdbIsFulfilled) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        window.removeEventListener('beforeunload', null)
        window.removeEventListener('unload', null)
        next()
      } else {
        // console.log(to, from)
        next(false)
      }
    } else {
      next()
    }
  },
  mounted() {
    this.fetchUserById()
    this.currentEmail = this.user.email
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else if (role.includes('admin')) {
        return 'Admin'
      } else {
        return 'Other'
      }
    },
    ppdbIsFulfilled() {
      return this.$store.state.ppdb.ppdbIsFulfilled
    },
  },
}
</script>

<style>
</style>
